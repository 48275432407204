@import "./colors";
@import "./mixins";

form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    padding: .5em;

    .form-row {
        padding: .5em 0;
        width: 100%;
        text-align: left;
        transition: all 1s;

        label {
            display: block;
        }

        input, select {
            width: 100%;

            &[type="file"] {
                background-color: $white;
                padding: 2em;
                border-style: dashed;
                text-align: center;
                cursor: pointer;

                &::file-selector-button {
                    display: none;
                }

                &:before {
                    visibility: hidden;
                    content: "";
                }
            }

            &:disabled {
                background-color: $white;
                border: none;
                cursor: default;
                padding: 0;
            }
        }

        textarea {
            width: 100%;
        }

        &.half { 
            width: calc(50% - .5em);
        }

        &.sm {
            width: calc(33% - .5em);
        }

        &.center {
            text-align: center;
        }

        &.buttons {
            width: 100%;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;

            button {
                width: 100%;
                flex: 1 0 auto;
            }
        }

        &.hide {
            opacity: 0;
            transform: translateY(-100%);
            z-index: -1;
        }

        &.show {
            opacity: 1;
            transform: translateY(0%);
            z-index: 0;
        }
    }
}

@media screen and (min-width: 512px) {
    form {
        .form-row {
            &.buttons {
                @include sizeCalculation(button, 3);
            }
        }
    }
}

@media screen and (min-width: 768px) {
    form {
        .form-row {
            input, select {
                width: 100%;
            }

            &.buttons {
                @include sizeCalculation(button, 4);
            }
        }
    }
}