#player-detail {
    form {
        width: 100%;
        align-items: center;

        .player-info {
            width: 100%;
        }
    }
}

@media screen and (min-width: 768px) {
    #player-detail {
        form {
            .player-info {
                width: 45%;
            }
        }
    }
}