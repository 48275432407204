@import "../colors";

.image-field {
    .file-input {
        display: none;
    }

    label {
        display: block;
        position: relative;
        width: 20em;
        height: 15em;
        max-width: 20em;
        max-height: 15em;
        cursor: pointer;
        margin: 0 auto;
        background-color: darken($white, 5);

        &:hover {
            .overlay {
                background-color: rgba($white, .9);
                transform: scale(.95);

                p {
                    color: $black;
                    font-weight: bold;
                    font-size: 1.25em;
                }
            }
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: auto;
            max-height: 100%;
            max-width: 100%;
        }
        
        .overlay {
            position: absolute;
            top: 2.5%;
            left: 2.5%;
            right: 2.5%;
            bottom: 2.5%;
            width: 95%;
            height: 95%;
            background-color: transparent;
            transition: all .25s;

            p {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: transparent;
                transition: all .25s;
                text-align: center;
            }
        }
    }
}