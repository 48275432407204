@import "../colors";
$lineHeight: 2.5em;

#award-page {
    position: relative;
    width: 100%;

    header {
        display: block;
        text-align: center;
        width: 100%;
        line-height: $lineHeight;
        background-color: $backgroundColor;
        z-index: 1;
    }

    > .content {
        position: relative;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        width: 100%;
        
        nav {
            position: sticky;
            top: 4em;
            z-index: 3;
            background-color: $white;
            width: 100%;

            .content {
                button {
                    width: 100%;
                }
            }
        }

        .wrapper {
            position: relative;

            .details {
                width: 100%;

                .award-section {
                    margin-bottom: 1em;
                    width: 100%;
                    // box-shadow: 2px 2px 10px $boxShadowColor;

                    header {
                        position: sticky;
                        top: 6em;
                        margin-bottom: .5em;
                        z-index: 2;
                        box-shadow: 0px 10px 10px $boxShadowColor;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    .section-content {
                        position: relative;
                        padding: .1em .5em;
                        width: 100%;

                        .award-question {
                            margin-bottom: 1em;
                            box-shadow: 0px 0px 10px $boxShadowColor;
                            padding: 1em 0;
                            scroll-margin-top: 4em + calc($lineHeight * 1) + 1.5em;

                            header {
                                padding: .5em;
                                top: 4em + calc($lineHeight * 1) + 1.5em;
                                z-index: 1;
                                line-height: 1.25em;
                            }

                            .question-content {
                                position: relative;
                                width: 100%;
                                padding: 0 .5em;

                                .multiple-choice-responses {
                                    width: 100%;
                                    text-align: center;
                                }
                                
                                .nominee {
                                    width: 100%;
                                    border: .15em solid $purple; 
                                    border-radius: .5em;
                                    margin: .5em 0;
                                    box-shadow: 0px 0px 10px $boxShadowColor;
                                    padding: .5em .25em;
                                    display: grid;
                                    transition: all .5s;
                                    grid-template-columns: 2em 1fr minmax(1em, 2em) 2em;
                                    grid-template-areas: 
                                        "rank title votes winner"
                                        "rank sub votes winner";

                                    &.winner {
                                        background-color: $buttonBackgroundSelected;
                                        color: $buttonBackgroundSelectedText;
                                        border-color: $buttonBackgroundActive;
                                        box-shadow: 0px 0px 10px $buttonBackgroundActive;
                                    }

                                    aside {
                                        grid-area: rank;
                                    }

                                    header {
                                        grid-area: title;
                                        box-shadow: none;
                                        background-color: initial;
                                    }
                                    
                                    sub {
                                        grid-area: sub;
                                    }

                                    .votes {
                                        grid-area: votes;
                                        text-align: right;
                                    }

                                    .winner-icon {
                                        grid-area: winner;
                                        line-height: 100%;

                                        .icon {
                                            transition: all .5s;
                                            width: 1.5em;
                                            height: 1.5em;
                                        }

                                        &.winner {
                                            .icon {
                                                fill: gold;
                                            }
                                        }
                                    }

                                    header, sub { 
                                        display: block; 
                                        position: initial; 
                                        top: initial; 
                                        line-height: initial; 
                                        text-align: center; 
                                        margin: 0;
                                        z-index: initial;
                                    }

                                    text-align: center;
                                    align-items: center;
                                    justify-content: center;
                                }

                                .winners { 
                                    display: flex;
                                    flex-wrap: wrap;
                                    flex-direction: row;
                                    width: 100%;
                                    align-items: center;
                                    justify-content: space-between;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #award-page {
        > .content {
            display: flex;

            nav {
                z-index: initial;
                align-self: flex-start;
                flex: 1 1 auto;
                padding: 0 .5em; 
                max-width: 30%;
                width: 25%;

                .collapsible-wrapper {
                    .flipper {
                        display: none;
                    }

                    &.hide {
                        .content {
                            grid-template-rows: 1fr;
                        }
                    }
                }
            }

            .wrapper {
                overflow: clip;
                flex: 0 1 auto;
                width: 75%;

                .details {
                    .award-section {
                        header {
                            top: 4em;
                        }

                        .section-content {
                            .award-question {
                                scroll-margin-top: 4em + calc($lineHeight * 1);

                                header {
                                    top: 4em + calc($lineHeight * 1);
                                }

                                .question-content {
                                    .winners {
                                        .nominee {
                                            width: calc(50% - .5em);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #award-page {
        > .content {
            .wrapper {
                .details {
                    .award-section {
                        .section-content {
                            .award-question {
                                .question-content {
                                    position: relative;
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    align-items: flex-start;
                                    justify-content: space-around;
                                    width: 100%;

                                    .team-nomination, .division-nomination {
                                        width: calc(50% - 1em);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
