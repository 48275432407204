@import "./colors";

#modal {
    position: relative;
    z-index: 99;
    overflow: hidden;

    &-background {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba($black, .75);
    }

    &-wrapper {
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        padding: 1em;
        background-color: $modalBackground;
        transform: translate(-50%, -50%);
        overflow-y: auto;

        #modal-content {
            height: 100%;

            &-close {
                position: absolute;
                right: .5em;
                top: .5em;
                text-align: right;
                z-index: 1;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #modal {
        &-wrapper {
            height: 70vh;
            width: 80%;
        }
    }
}

@media screen and (min-width: 1024px) {
    #modal {
        &-wrapper {
            max-width: 70vw;
        }
    }
}