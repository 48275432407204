@import "./colors";

.menu {
    position: relative;
    width: 100%;

    &-title {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        height: 32px;

        // .icon {
        //     display: inline-flex;
        //     align-items: center;
        //     justify-content: center;
        //     line-height: 2em;
        //     height: 32px;
        // }
    }

    &-content {
        position: absolute;
        transform: scaleY(0);
        transform-origin: top center;
        transition: all .25s;
        justify-content: space-between;
        background-color: white;
        left: 0; right: 0;
        // width: 100%;
        padding: 0 .5em .5em .5em;

        &.show { 
            transform: scaleY(1);
        }

        > * {
            display: block;
            width: 100%;
        }
    }
}