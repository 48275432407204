.collapsible-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;

    .header {
        text-align: center;
        cursor: pointer;

        .title {
            display: inline-block;
            line-height: 2em;
        }

        .flipper {
            position: relative;
            transition: all .5s;
            margin: 0 .5em;
            width: 1.25em;
            height: 1.25em;
        }
    }

    .content {
        display: grid;
        grid-template-rows: 0fr;
        transition: all .5s;
        
        > div {
            padding: 0 .5em;
            overflow: auto;
            height: 100%;
        }
    }
    

    &.show {
        .header {
            .flipper {
                transform: rotate(405deg);
            }
        }

        .content {
            grid-template-rows: 1fr;
            max-height: calc(100vh - 7em);

            > div {
                overflow: auto;
            }
        }
    }
}