#page--player-detail {
    ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        li {
            width: 100%;
        }
    }

    .pages {
        text-align: center; 
        .nav {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-evenly;
        }
    }
}

@media screen and (min-width: 768px) {
    #page--player-detail {
        ul {
            li {
                width: calc(50% - 1em);
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #page--player-detail {
        ul {
            li {
                width: calc(33% - 1em);
            }
        }
    }
}