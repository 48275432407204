@import "_colors.scss";

.season-teams {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    background-color: $white;

    .season-team {
        width: 100%;
        padding: .5em;
        
        .card-header {
            position: relative;
            cursor: pointer;
            padding: 0 .5em;

            h3 {
                display: flex;
                text-align: left;
                position: relative;

                .team-name {
                    position: relative;
                    display: flex;
                    flex: 1 0 auto;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .name {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .icon {
                        width: 1.25em;
                        height: 1.25em;
                    }
                }

                .collapsible-menu-button {
                    position: relative;
                    right: 1em;

                    &:before {
                        transition: all .25s;
                        display: inline-block;
                        border: .5rem solid black;
                        border-bottom: none;
                        border-right: none;
                        transform: rotateZ(45deg);
                        transform-origin: center;
                        width: .5em;
                        height: .5em;
                        content: " ";
                    }
            
                    &.expanded {
                        &:before {
                            transform: rotateZ(225deg);
                        }
                    }
                }
            }
        }

        .card-content {
            cursor: initial;
        }

        .season-team-players {
            width: 100%;
            height: auto;
            transition: all .5s;

            &.content-hide {
                transition: all .5s;
                height: 0;
                overflow: hidden;
            }

            .players {
                .player-header {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 2px solid $black;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .season-teams {
        .season-team {
            width: calc(50% - .25em);
        }
    }
}