@import "../colors";

#section-form {
    position: relative;
    height: 100%;
    width: 100%;

    form {
        // height: 100%;
    }

    .buttons {
        padding: 0;
        position: relative;
        bottom: 0;
        // height: 3em;
        
        button {
            margin: 0;
        }
    }
}