@import "./colors";
@import "./base";

#app {
    position: relative;
    height: 100%;
    width: 100%;

    #app-header {
        position: sticky;
        top: 0;
        background-color: $purple;
        color: $white;
        height: 4em;
        width: 100%;
        z-index: 10;

        .header-content {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1em;
            height: 4em;
            width: 100%;
            margin: 0 auto;

            #site-logo { 
                display: none;
                height: 100%;
                max-width: 15em;
                max-height: 5em;
                align-self: flex-start;
                padding: .25em;

                img {
                    height: auto;
                    width: auto;
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            #site-title {
                font-family: "Agenor";
                font-weight: bold;
                text-align: center;
                user-select: none;
                width: 100%;
                height: 100%;
                font-size: 3.5em;
            }

            #user-info {
                width: 2em;
                max-width: 2em;
                text-align: right;
                

                .icon {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    stroke: $white;
                    fill: $white;

                    &:hover {
                        fill: $buttonBackgroundActive;
                        stroke: $buttonBackgroundActive;
                    }
                }
            }

            #nav-container {
                position: relative;

                #nav-main-menu {
                    position: fixed;
                    overflow: hidden;
                    transition: all .25s ease;
                    background-color: $white;
                    padding: 1em;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    transform: translateX(100%);
                    color: $black;

                    &.show {
                        transform: translateX(0%);
                    }

                    .close-icon {
                        width: 100%;
                        text-align: right;
                    }

                    .link {
                        display: block;
                        padding: .25em;
                        margin: .25em 0;
                    }

                    .site-info {
                        position: fixed;
                        text-align: center;
                        bottom: 0;
                        width: 100%;
                        padding: 1em 0;

                        sub {
                            display: block;
                            font-size: .8em;
                        }
                    }
                }
            }
        }
    }

    #app-content {
        margin: 0 auto;
        width: 100%;
        padding: .5em;
    }

    footer {
        position: relative;
        width: 100%;
        height: 3em;
        bottom: 1em;
        padding: 1em;
        text-align: center;
        font-size: medium;
        font-style: italic;
        z-index: -1;
    }
}

@media screen and (min-width: 768px) {
    #app {
        #app-header{
            .header-content {
                // width: 768px;

                #site-logo {
                    display: block;
                    max-width: 8.5em;
                }

                #nav-container {
                    #nav-main-menu {
                        
                        left: calc(100% - 15em);
                        width: 15em;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #app {
        #app-header{
            .header-content {
                // max-width: 1024px;
                width: 100%;

                #site-logo {
                    max-width: 15em;
                }
            }
        }

        #app-content {
            margin: auto;
            // max-width: 1024px;
        }
    }
}

@media screen and (min-width: 1268px) {
    #app {
        #app-header{
            .header-content {
                // max-width: 1268px;
            }
        }

        #app-content {
            max-width: 1268px;
        }
    }
}