@import "./colors";

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.admin-season {
    header#season-header {
        width: 100%;
        top: 3.25em;
        z-index: 2;
        background-color: $white;
        padding: .25em;
        text-align: center;
        flex-direction: column;

        .menu {
            display: inline-block;
            z-index: 4;

            .active {
                background-color: $purple;
            }
            
            .integrations {
                button {
                    margin: 0 .5em;
                }

                .icon {
                    width: 1.5em;
                    height: 1.5em;

                    &.leagueapps {
                        fill: white;
                        background-color: green;
                        border-radius: 100%;
                        padding: 2px;
                    }
                }
            }
        }

        // > div {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        // }

        .icon-refresh {
            &.refreshing {
                animation: rotate 1s infinite linear;
            }
        }
    }

    // .import-options {
    //     position: relative;
    //     text-align: center;

    //     .buttons {
    //         position: relative;
    //         display: flex;
    //         flex-wrap: wrap;
    //         align-items: stretch;
    //         justify-content: space-evenly;
    //         width: 100%;

    //         > div {
    //             width: 100%;
    //             padding: .5em 0;

    //             button {
    //                 height: 100%;
    //                 width: 100%;
    //             }
    //         }
    //     }
    // }
}

@media screen and (min-width: 768px) {
    .admin-season {
        // .season-header {
        //     text-align: center;

        //     .season-actions {
        //         width: 50%;
        //         align-items: center;
        //     }
        // }
        // .import-options {
        //     .buttons {
        //         > div {
        //             width: 33%;
        //         }
        //     }
        // }
    }
}