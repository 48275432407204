.input-field {
    border: none;
    padding: .5em 0;

    label {
        display: block;
    }

    input {
        display: block;
        width: 100%;
    }

    &:has(input:disabled) { 
        display: none;
    }
}